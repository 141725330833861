import React from "react";
import { Helmet } from "react-helmet";

import "../scss/index.scss";

export default function Home() {
    return (
        <main className="main" role="main">
            <Helmet htmlAttributes={{ lang : 'de' }}>
                <title>Waldbauer Alm</title>
                <meta name="description" content="Wunderschöne Alm in Hochgößnitz" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://waldbauer-alm.at" />
                <meta property="og:title" content="Waldbauer Alm" />
                <meta property="og:description" content="Wunderschöne Alm in Hochgößnitz" />

                <script defer data-domain="waldbauer-alm.at" src="https://plausible.thomaswilhelm.at/js/plausible.js"></script>
            </Helmet>

            <section className="hero">
                <div className="container is-hero">
                    <header className="hero-title">
                        <span role="figure" aria-label="Alm Illustration">
                            <svg width="64px" height="64px" version="1.1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                                <g transform="scale(2.66667)">
                                    <path fill="none" stroke="#D4A373" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6.75,12.75v4.58"></path>
                                    <path fill="none" stroke="#E9EDC9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7.374,1.084l1.89742e-08,2.84558e-08c-0.229796,-0.344626 -0.695456,-0.437714 -1.04008,-0.207918c-0.0823462,0.0549082 -0.15301,0.125572 -0.207918,0.207918l-3.876,6.416h2.25l-2.9,4.128l-4.02766e-08,7.051e-08c-0.20545,0.359671 -0.0804303,0.817792 0.27924,1.02324c0.112933,0.0645095 0.240701,0.0985424 0.37076,0.0987576h9l3.41984e-08,-1.10294e-11c0.414214,-0.000133599 0.749892,-0.336028 0.749758,-0.750242c-4.2058e-05,-0.130398 -0.0340809,-0.258531 -0.0987581,-0.371758l-2.901,-4.128h2.25Z"></path>
                                    <path fill="none" stroke="#D4A373" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.75,10.5v4.03"></path>
                                    <path fill="none" stroke="#E9EDC9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19.262,1.023l1.54385e-08,2.3102e-08c-0.188414,-0.281941 -0.569712,-0.35776 -0.851654,-0.169346c-0.0670173,0.0447859 -0.124561,0.102329 -0.169346,0.169346l-3.172,4.977h1.841l-2.578,3.582l3.04338e-08,-5.3058e-08c-0.168448,0.29367 -0.066935,0.66829 0.226735,0.836737c0.0932023,0.0534604 0.198819,0.0814842 0.306265,0.0812627h7.77l3.42809e-08,7.06848e-11c0.33855,0.000698053 0.613565,-0.273185 0.614263,-0.611735c0.000221542,-0.107446 -0.0278023,-0.213063 -0.0812627,-0.306265l-2.576,-3.582h1.841Z"></path>
                                    <path fill="none" stroke="#FEFAE0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M0.805,18l7.24671e-07,-2.51827e-07c5.81123,-2.01943 12.2531,0.140041 15.673,5.254"></path>
                                    <path fill="none" stroke="#FEFAE0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M10.969,18.518l2.28299e-07,-2.149e-07c2.91742,-2.7462 6.77439,-4.2731 10.781,-4.268c0.506,0 1.006,0.0233333 1.5,0.07"></path>
                                </g>
                            </svg>
                        </span>
                        <h1>Waldbauer Alm</h1>
                    </header>
                    <p className="hero-content">
                        <a target="_blank" href="https://www.google.com/maps/place/Waldbauer+Alm/@47.0663357,14.9540299,15.1z/data=!4m5!3m4!1s0x0:0xc202876ba354667a!8m2!3d47.066646!4d14.954392" rel="noreferrer">
                            Hochgößnitz 40, 8591 Hochgößnitz
                        </a>
                    </p>
                    <footer className="hero-credits" role="contentinfo" lang="en">
                        Made with <span role="img" aria-label="heart emoji">❤️</span> by <a href="https://thomaswilhelm.at">Thomas Wilhelm</a>
                    </footer>
                </div>
            </section>
        </main>
    );
}
